import { Injectable } from '@angular/core';

import { environment } from '~/environments/environment';

@Injectable({ providedIn: 'root' })
export class MediaService {
  resolveUserAvatarUrl(id: string) {
    return [
      environment.mediaUrl,
      'media',
      'resource',
      '200',
      'user_avatar',
      id,
    ].join('/');
  }
}
